import { FC } from 'react';
import {
  privacyNoteSectionData,
  PrivacyNoteSectionDataByPageName,
} from '@/data/static/ts/privacyNoteSectionData';
import SmallLightGreyParagraph from '@/components/global/typography/paragraph/SmallLightGreyParagraph';

interface PrivacyNoteSectionProps {
  page: PrivacyNoteSectionDataByPageName;
  className?: string;
}

const PrivacyNoteSection: FC<PrivacyNoteSectionProps> = ({
  page,
  className,
}) => {
  const { text } = privacyNoteSectionData[page];
  return (
    <div className={className}>
      <SmallLightGreyParagraph className={'text-center align-middle'}>
        <b className={'mr-1'}>Please Note!</b>
        {text}
      </SmallLightGreyParagraph>
    </div>
  );
};

export default PrivacyNoteSection;

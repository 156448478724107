'use client';

import { BrowserView, isChrome, isMobile } from 'react-device-detect';
import ReactPlayer from 'react-player/file';
import { FC, useEffect, useState } from 'react';

const IntroVideo: FC = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [introVideoUrl, setIntroVideoUrl] = useState('/home/intro.webm');

  // useEffect(() => {
  //   setIsMounted(true);
  // }, []);

  useEffect(() => {
    setIntroVideoUrl(isChrome ? '/home/intro.webm' : '/home/home-intro.mp4');
  }, [isChrome]);

  return (
    <BrowserView>
      {/*{isMounted && (*/}
        <ReactPlayer
          url={introVideoUrl}
          width={'100%'}
          height={'auto'}
          playing={true}
          loop={true}
          muted
        />
      {/*)}*/}
    </BrowserView>
  );
};

export default IntroVideo;

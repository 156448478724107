import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

interface PrimaryButtonProps extends React.ComponentProps<typeof Button> {
  $rotatedegree?: number;
}

const PrimaryGreenTransformedButton = styled(Button)<PrimaryButtonProps>`
  &&& {
    background-color: #1a847c;
    border-color: #1a847c;
    border-radius: 4px;
    color: #ffffff;
    padding-left: 46px;
    padding-right: 46px;
    font-weight: 700;
    font-size: 46px;
    line-height: 62px;
    height: 66px;
    align-items: center;
    display: flex;
    justify-content: center;
    transform: ${({ $rotatedegree }) =>
      $rotatedegree ? `rotate(${$rotatedegree}deg)` : 'none'};

    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 48px;
    }

    &:active,
    &:hover,
    &:focus,
    &:not(:disabled):hover {
      background-color: #1a847c !important;
      border-color: #1a847c !important;
      color: #ffffff !important;
    }
  }
`;

export default PrimaryGreenTransformedButton;

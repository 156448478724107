'use client';

import styled from 'styled-components';
import React, { ReactNode } from 'react';
import { TextProps } from 'antd/es/typography/Text';

const PrimaryGreenTextStyled = styled.span`
  color: #1a847c;
`;

interface GreenTextProps extends TextProps {
  children: ReactNode;
}

const PrimaryGreenText: React.FC<GreenTextProps> = ({ children, ...props }) => {
  return <PrimaryGreenTextStyled {...props}>{children}</PrimaryGreenTextStyled>;
};

export default PrimaryGreenText;

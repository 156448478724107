import styled from 'styled-components';

const SecureDataSectionStyled = styled.div`
  padding: 0 72px;
  .section-content {
    padding: 80px;
    width: 100%;
    background: #272a3d;
    border-radius: 20px;
    display: flex;
    row-gap: 80px;
    .safe-data-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-right: 80px;
      justify-content: center;
    }
  }

  @media (max-width: 768px) {
    padding: 0;
    .section-content {
      flex-direction: column;
      padding: 40px 16px;
      .safe-data-wrapper {
        padding-right: 0;
      }
    }
  }
`;

export default SecureDataSectionStyled;

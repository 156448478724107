import styled from 'styled-components';

const GlobalRecognitionStyled = styled.div`
  .global-recognition-content {
    display: none;
  }

  .featured-by-container {
    padding: 0 40px;
    .featured-by-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 0 103px;
      .featured-by-link {
        display: inline-block;
        .ant-image {
          height: 68px;
          .featured-by-img {
            height: 100% !important;
            width: auto !important;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .global-recognition-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .content-title {
        margin-bottom: 16px;
      }
      .content-text {
        margin-bottom: 40px;
      }
    }
    .featured-by-container {
      padding: 0;
      .featured-by-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        .featured-by-link {
          margin-right: 32px;
          margin-bottom: 16px;
          height: 52px;
          .ant-image {
            height: 52px;
          }
          &.forbes {
            order: 3;
          }
          &.usatoday {
            order: 1;
          }
          &.entrepreneur {
            order: 2;
          }
          &.cnbc {
            order: 4;
          }
          &:first-child,
          &:last-child {
            margin-bottom: 0;
          }
          &:nth-child(3),
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
`;

export default GlobalRecognitionStyled;

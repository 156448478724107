import styled from 'styled-components';


//TODO: need refactor ASAP

const HomeServiceCardStyled = styled.div`
  display: flex;
  padding: 24px;
  background-color:  #F5F5F5;
    border: 1px solid #EAEAEC;
  border-radius: 8px;
  transition: 0.2s;
  height: 100%;
    
    &:hover {
        box-shadow: 0px 5px 20px 3px #272A3D0D;
    }
    
    .main-title {
        font-size: 30px !important;
        font-weight: 700 !important;
        line-height: 46px !important;
        text-align: left !important;
    }
    
    a {
        text-decoration: none !important;
    }

  img {
    max-width: 104px;
    max-height: 104px;
  }

    .simple-steps-wrapper {
        display: flex;
        justify-content: flex-start;
        .simple-steps {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            & > div {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    



  @media (max-width: 768px) {
      
      .main-title {
          font-size: 24px !important;
          font-weight: 700 !important;
          line-height: 36px !important;
          text-align: left !important;
      }

      a {
          font-size: 16px !important;
      }
      
      h3 {
          font-size: 18px !important;

      }

      img {
          max-width: 72px;
          max-height: 72px;
      }
      
    flex-direction: column;
    padding: 16px;
    .img-wrapper {
      width: 64px;
      height: 64px;
      margin-right: auto;
      margin-bottom: 24px;
    }

    .card-content {
      margin-right: 0;
      margin-bottom: 16px;
      .card-title {
      }
      .card-text {
      }
    }

    .icon-container {
      width: 100%;
    }
  }
`;

export default HomeServiceCardStyled;

'use client';

import React, { MouseEventHandler } from 'react';
import { Col } from 'antd';
import Row from 'antd/lib/row';
import { SIGN_UP_URL } from '@/utils/config';
import { Analytics } from '@/libs/GoogleAnalytics';
import IntroVideo from '@/components/pages/home/IntroVideo';
import IntroSectionStyled from '@/components/pages/home/IntroSection/style';
import HipaaGdprSection from '@/components/common/sections/HipaaGdprSection';
import PrimaryContainer from '@/components/global/containers/PrimaryContainer';
import PrimaryGreenButton from '@/components/global/buttons/PrimaryGreenButton';
import PrivacyNoteSection from '@/components/common/sections/PrivacyNoteSection';
import MainParagraph from '@/components/global/typography/paragraph/MainParagraph';
import PrimaryGreenText from '@/components/global/typography/text/PrimaryGreenText';
import DarkHeading1 from '@/components/global/typography/heading/Heading1/DarkHeading1';
import TrustPilotSection from '@/components/common/sections/TrustPilotSection';

const IntroSection = () => {
  const handleSignUpBtnClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    Analytics.sendSignUpStartEventToGA();
  };

  return (
    <PrimaryContainer>
      <IntroSectionStyled>
        <div className={'intro-general-section'}>
          <div className={'text-content-section'}>
            <DarkHeading1 className={'content-title'}>
              Your Personal
              <br /> Health Assistant <br />
              <PrimaryGreenText className={''}>Powered by AI</PrimaryGreenText>
            </DarkHeading1>
            <MainParagraph className={'content-text'}>
              Prevent potential health issues and effectively manage your
              health. Gain insights with our AI Doctor and validate them with
              top US and European doctors.
            </MainParagraph>
            <Row gutter={[0, 40]}>
              <Col xs={24}>
                <PrimaryGreenButton
                  onClick={handleSignUpBtnClick}
                  href={SIGN_UP_URL}
                  className={'primary-green-btn'}
                >
                  Try Docus for Free
                </PrimaryGreenButton>
              </Col>
              <Col xs={24} md={{ flex: 'none' }}>
                <TrustPilotSection />
              </Col>
            </Row>
          </div>
          <div className={'img-content-section'}>
            <IntroVideo />
          </div>
        </div>
        <Row gutter={[0, 24]}>
          <Col xs={24}>
            <PrivacyNoteSection page={'home'} />
          </Col>
          <Col xs={24}>
            <HipaaGdprSection />
          </Col>
        </Row>
      </IntroSectionStyled>
    </PrimaryContainer>
  );
};

export default IntroSection;

import styled from 'styled-components';

const StyledMediumCardsWithIconSection = styled.div`
  .how-to-use-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .content-title {
      margin-bottom: 24px;
    }
    .content-text {
      margin-bottom: 40px;
    }
  }

  .cards-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    .htu-card-container {
      background-color: #e9f3f2;
      border-radius: 8px;
      border: 1px solid #d1e6e5;
      max-width: 364px;
      width: 100%;
      padding: 24px;
      margin-right: 24px;
      .htu-card-icon-wrapper {
        margin-bottom: 16px;
        svg {
          color: #1a847c;
        }
      }
      .htu-card-title-wrapper {
        margin-bottom: 16px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .assessment-btn-wrapper {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 768px) {
    .how-to-use-content {
      align-items: flex-start;
      .content-title {
        margin-bottom: 16px;
      }
      .content-text {
      }
    }
    .cards-wrapper {
      flex-direction: column;
      align-items: center;
      .htu-card-container {
        margin-right: 0;
        padding: 24px 16px;
        margin-bottom: 24px;
        max-width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .assessment-btn-wrapper {
      .assessment-btn {
        width: 100%;
      }
    }
  }
`;

export default StyledMediumCardsWithIconSection;

import {
  faClock,
  faHandHoldingMedical,
  faMicrochipAi,
} from '@awesome.me/kit-b89bd90d95/icons/classic/solid';

export const AIDoctorsCardsData = [
  {
    id: 1,
    icon: faClock,
    title: '24/7 Availability',
    text: 'Dr. AI has no working hours and never gets tired. Use our chatbot anytime, anywhere, and ask as many questions as you want.',
  },
  {
    id: 2,
    icon: faHandHoldingMedical,
    title: 'Personalization',
    text: 'AI Doctor screenings are tailored to your specific needs, health history, and even lifestyle habits, helping to closely align AI with medical diagnoses.',
  },
  {
    id: 3,
    icon: faMicrochipAi,
    title: 'Powered by NLP Models',
    text: 'Our AI Doctor is driven by a natural language model, which enables it to approach your health inquiries with advanced intelligence and understanding.',
  },
];

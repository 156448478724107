import {
  faGlobe,
  faSpa,
  faUniversalAccess,
} from '@awesome.me/kit-b89bd90d95/icons/classic/regular';

export const benefitCardsData = [
  {
    id: 1,
    icon: faGlobe,
    title: 'Access to world-class doctors',
    text: 'Get access to world-class knowledge and expertise. Maximize your chances of receiving accurate diagnosis and treatment.',
  },
  {
    id: 2,
    icon: faUniversalAccess,
    title: 'Affordability',
    text: 'No need to leave your home. Save your time and effort, eliminate the stress and travel expenses.',
  },
  {
    id: 3,
    icon: faSpa,
    title: 'Peace of mind',
    text: 'Obtain peace of mind and reassurance, knowing that you have explored all available options and made the best possible decision for your health.',
  },
];

import { Col, Image, Row } from 'antd';

const HipaaGdprSection = () => {
  return (
    <Row gutter={40} justify={'center'} align={'middle'}>
      <Col>
        <Image
          src={'/images/HIPAA.png'}
          alt={'HIPAA compliant - Docus'}
          preview={false}
          loading={'eager'}
          width={120}
        />
      </Col>
      <Col>
        <Image
          src={'/images/GDPR.png'}
          alt={'GDPR compliant - Docus'}
          preview={false}
          loading={'eager'}
          width={72}
        />
      </Col>
    </Row>
  );
};

export default HipaaGdprSection;

export const secureDataSectionData = {
  general: {
    textFirst:
      'No personally identifiable information is required to utilize the Docus AI Health Assistant and its associated features.',
    textSecond:
      'Any data shared while using our services is safeguarded by advanced security protocols, ensuring compliance with HIPAA and GDPR.',
  },
  'ai-doctor': {
    textFirst:
      'No online tool can replace professional medical advice. Always consult a professional before taking any action.',
    textSecond:
      'Docus AI Doctor doesn’t need any personally identifiable information to provide you with relevant insights and recommendations.',
    textThird:
      'Any data shared while using our services is safeguarded by advanced security protocols, ensuring compliance with HIPAA and GDPR.',
  },
  'lab-test-interpretation': {
    textFirst:
      "We don't need any personally identifiable information to analyze your lab tests. Feel free to crop or black out any personal details.",
    textSecond:
      'Any data shared while using our services is safeguarded by advanced security protocols, ensuring compliance with HIPAA and GDPR.',
  },
  'blood-test': {
    textFirst:
      "We don't need any personally identifiable information to analyze your lab tests. Feel free to crop or black out any personal details.",
    textSecond:
      'Any data shared while using our services is safeguarded by advanced security protocols, ensuring compliance with HIPAA and GDPR.',
  },
  'urine-test': {
    textFirst:
      "We don't need any personally identifiable information to analyze your lab tests. Feel free to crop or black out any personal details.",
    textSecond:
      'Any data shared while using our services is safeguarded by advanced security protocols, ensuring compliance with HIPAA and GDPR.',
  },
};

export type SecureDataSectionDataType = keyof typeof secureDataSectionData;

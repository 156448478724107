'use client';

import React from 'react';
import { Col, Row } from 'antd';
import {
  secureDataSectionData,
  SecureDataSectionDataType,
} from '@/data/static/ts/secureDataSectionData';
import {
  faKey,
  faLockKeyhole,
} from '@awesome.me/kit-b89bd90d95/icons/classic/light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PrimaryContainer from '@/components/global/containers/PrimaryContainer';
import { faShieldCheck } from '@awesome.me/kit-b89bd90d95/icons/classic/regular';
import WhiteParagraph from '@/components/global/typography/paragraph/WhiteParagraph';
import SecureDataSectionStyled from '@/components/pages/home/SecureDataSection/style';
import BigWhiteHeading2 from '@/components/global/typography/heading/Heading2/BigWhiteHeading2';
import PrimaryGreenTransformedButton from '@/components/global/buttons/PrimaryGreenTransformedButton';

type SectionProps = {
  type?: SecureDataSectionDataType;
};

const SecureDataSection: React.FC<SectionProps> = ({ type }) => {
  const texts = type
    ? secureDataSectionData[type]
    : secureDataSectionData.general;

  return (
    <PrimaryContainer>
      <SecureDataSectionStyled>
        <div className={'section-content'}>
          <div className={'safe-data-wrapper'}>
            <FontAwesomeIcon
              icon={faLockKeyhole}
              className={'mb-10'}
              color={'#ffffff'}
              size={'3x'}
            />
            <BigWhiteHeading2 className={'mb-6 whitespace-nowrap'}>
              Your health data <span className={'hidden'}>is safe</span>
            </BigWhiteHeading2>
            <PrimaryGreenTransformedButton $rotatedegree={-3}>
              Is safe
            </PrimaryGreenTransformedButton>
          </div>
          <Row gutter={[0, 24]}>
            <Col xs={24}>
              <WhiteParagraph
                className={
                  'flex flex-row justify-between justify-items-start font-semibold'
                }
              >
                <FontAwesomeIcon icon={faKey} className={'mr-4 mt-2'} />
                {texts.textFirst}
              </WhiteParagraph>
            </Col>
            <Col xs={24}>
              <WhiteParagraph
                className={
                  'flex flex-row justify-between justify-items-start font-semibold'
                }
              >
                <FontAwesomeIcon icon={faShieldCheck} className={'mr-4 mt-2'} />
                {texts.textSecond}
              </WhiteParagraph>
            </Col>
          </Row>
        </div>
      </SecureDataSectionStyled>
    </PrimaryContainer>
  );
};

export default SecureDataSection;

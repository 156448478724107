export const privacyNoteSectionData = {
  home: {
    text: 'Your data is confidential and secured by HIPAA and GDPR standards.',
  },
  'home-chat': {
    text: 'This tool is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always consult a professional before taking any actions.',
  },
  'ai-doctor': {
    text: 'Your data is confidential and secured by HIPAA and GDPR standards.',
  },
  'ai-doctor/chat': {
    text: 'This tool is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always consult a professional before taking any action.',
  },
  'ai-health-assistant': {
    text: 'This tool is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always consult a professional before taking any actions.',
  },
  'knowledge-base-landing': {
    text: 'This tool is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always consult a professional before taking any actions.',
  },
  'symptom-checker': {
    text: 'This tool is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always consult a professional before taking any actions.',
  },
  'symptom-checker-landing': {
    text: 'Your data is confidential and secured by HIPAA and GDPR standards.',
  },
  'symptom-checker-for-adults': {
    text: 'Your data is confidential and secured by HIPAA and GDPR standards.',
  },
  'symptom-checker-for-men': {
    text: 'Your data is confidential and secured by HIPAA and GDPR standards.',
  },
  'symptom-checker-for-women': {
    text: 'Your data is confidential and secured by HIPAA and GDPR standards.',
  },
  'child-symptom-checker': {
    text: 'Your data is confidential and secured by HIPAA and GDPR standards.',
  },
  'lab-test-interpretation': {
    text: 'Your data is confidential and secured by HIPAA and GDPR standards.',
  },
  'blood-test': {
    text: 'Your data is confidential and secured by HIPAA and GDPR standards.',
  },
};

export type PrivacyNoteSectionDataByPageName =
  keyof typeof privacyNoteSectionData;

'use client';

import PrimaryContainer from '@/components/global/containers/PrimaryContainer';
import DarkHeading2 from '@/components/global/typography/heading/Heading2/DarkHeading2';
import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import MainParagraph from '@/components/global/typography/paragraph/MainParagraph';
import HomeServiceCard from '@/components/pages/home/ServicesSection/HomeServiceCard';

const ServicesSectionStyled = styled.div`
  .services-title,
  .services-slogan {
    text-align: center;
  }
  @media (max-width: 768px) {
    .services-title,
    .services-slogan {
      text-align: left;
    }
  }
`;

const ServicesSection = () => {
  const aiDoctorFeatures = [
    'Get tailored insights',
    'Discover your health risks',
    'Get personal checkup plans',
    'Generate health reports',
  ];

  return (
    <PrimaryContainer>
      <ServicesSectionStyled>
        <DarkHeading2 className={'services-title  pb-6 '}>
          Make Informed Health Decisions
        </DarkHeading2>
        <MainParagraph className={'mb-10 services-slogan'}>
          We have all you need to better understand your health and make
          informed and accurate decisions about your well-being.
        </MainParagraph>
        <Row gutter={[24, 24]} justify={'space-between'}>
          <Col xs={24} md={12}>
            <HomeServiceCard
              title="Personal AI Doctor"
              description="Customize your personal AI Doctor. Simply complete your health profile, ask your health questions, and receive personalized, data-driven advice."
              imageUrl="/home/services/home_ai_doctor.png"
              alt={'Personal AI Doctor'}
              link={'/ai-doctor'}
              linkText={'CHAT WITH AI DOCTOR'}
              list={aiDoctorFeatures}
            />
          </Col>
          <Col xs={24} md={12}>
            <Row gutter={[24, 24]} align={'stretch'} className={'h-full'}>
              <Col xs={24}>
                <HomeServiceCard
                  title="Lab Test Interpretation"
                  description="Upload your lab results to receive in-depth biomarker interpretations and detailed lab test reports."
                  imageUrl="/home/services/home_lab_tests.png"
                  alt={'Lab Test Interpretation'}
                  link={'/lab-test-interpretation'}
                  linkText={'GO TO LAB TESTS'}
                />
              </Col>
              <Col xs={24}>
                <HomeServiceCard
                  title="Top Doctors Access"
                  description="Connect with over 350 top doctors from the US and Europe for expert second opinions and validation of AI-driven health insights."
                  imageUrl="/home/services/home_top_doctors.png"
                  alt={'Top Doctors Access'}
                  link={'/second-opinion'}
                  linkText={'GET SECOND OPINION'}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </ServicesSectionStyled>
    </PrimaryContainer>
  );
};

export default ServicesSection;


import { faSquarePollHorizontal, faStreetView, faHandHoldingMedical } from '@awesome.me/kit-b89bd90d95/icons/classic/regular';

export const HomeServicesData = [
  {
    id: 1,
    icon: faHandHoldingMedical,
    title: 'Proactive Preventive Care',
    text: 'Utilize predictive analytics and expert advice to identify and manage health risks early, ensuring your long-term wellness.',
  },
  {
    id: 2,
    icon: faStreetView,
    title: 'Tailored Health Solutions',
    text: 'Get health support tailored to your needs with personalized plans and strategies, available 24/7 to seamlessly fit your lifestyle.',
  },
  {
    id: 3,
    icon: faSquarePollHorizontal,
    title: 'In-Depth Health Insights',
    text: 'Gain a deeper understanding of your health with clear, actionable insights, helping you make informed decisions effortlessly.',
  },
];

import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

interface PrimaryButtonProps {
  children: React.ReactNode;
  width?: string;
  bgColor?: string;
}

const PrimaryGreenButton = styled(Button)<PrimaryButtonProps>`
  &&& {
    background-color: #1A847C;
    border-color: #1A847C;
    border-radius: 4px;
    color: #fff;
    padding: 12px 24px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    height: 52px;
    width: ${(props) => (props.width ? `${props.width}` : 'auto')};  
    transition: .2s;
      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):hover,
      &:not(:disabled):active,
      &:not(:disabled):focus {
          background-color: #12635C;
          border-color: #12635C;
          color: #fff;
      }
    @media (max-width: 768px) {
      width: ${(props) => (props.width ? `${props.width}` : '100%')};
    }
      
  }
}
`;

export default PrimaryGreenButton;

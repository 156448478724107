'use client';

import React, { FC, MouseEventHandler } from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { SIGN_UP_URL } from '@/utils/config';
import { Analytics } from '@/libs/GoogleAnalytics';
import { faCheck } from '@awesome.me/kit-b89bd90d95/icons/classic/solid';
import ContainerFluid from '@/components/global/containers/ContainerFluid';
import WhiteIconText from '@/components/global/typography/text/WhiteIconText';
import PrimaryContainer from '@/components/global/containers/PrimaryContainer';
import DocusRedLinkButton from '@/components/global/buttons/DocusRedLinkButton';
import WhiteParagraph from '@/components/global/typography/paragraph/WhiteParagraph';
import WhiteHeading2 from '@/components/global/typography/heading/Heading2/WhiteHeading2';

interface SectionProps {
  description?: string;
}

const OneClickJourneyDarkSectionStyled = styled(PrimaryContainer)`
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 0;
  .icon-texted-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    .texted-icon {
    }
  }

  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
    .icon-texted-container {
      flex-direction: column;
      .texted-icon {
        &:first-child {
          margin-bottom: 8px;
        }
      }
    }
  }
`;

const OneClickJourneyDarkSection: FC<SectionProps> = ({ description }) => {
  const handleSignUpBtnClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.stopPropagation();
    Analytics.sendSignUpStartEventToGA();
  };

  const titleClassName = description ? 'mb-6' : 'mb-10';
  return (
    <ContainerFluid bgColor={'#272A3D'}>
      <OneClickJourneyDarkSectionStyled className={''}>
        <Row gutter={32} justify={'center'}>
          <Col xs={24} md={12} className={'text-center'}>
            <WhiteHeading2 className={titleClassName}>
              You’re only one click away from a life-changing journey
            </WhiteHeading2>
            {description && (
              <WhiteParagraph className={'mb-10'}>{description}</WhiteParagraph>
            )}
          </Col>
          <Col xs={24} className={'flex justify-center mb-6'}>
            <DocusRedLinkButton
              onClick={handleSignUpBtnClick}
              href={SIGN_UP_URL}
            >
              Try Docus for Free
            </DocusRedLinkButton>
          </Col>
          <Col className={'icon-texted-container'}>
            <WhiteIconText
              className={'texted-icon'}
              icon={faCheck}
              text={'Virtual health assistant powered by AI'}
            />
            <WhiteIconText
              className={'texted-icon'}
              icon={faCheck}
              text={'350+ world-renowned Doctors'}
            />
          </Col>
        </Row>
      </OneClickJourneyDarkSectionStyled>
    </ContainerFluid>
  );
};

export default OneClickJourneyDarkSection;

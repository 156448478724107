import { Image } from 'antd';
import React from 'react';
import MainLink from '@/components/global/typography/links/MainLink';

const FeaturedBySection = () => {
  const featuredData = [
    {
      href: 'https://www.forbes.com/sites/cindygordon/2023/06/12/how-docusai-is-powering-a-groundbreaking-ai-powered-health-platform/?sh=1e22377976fd',
      imageSrc: '/symptom-checker/featured/forbes.png',
      alt: 'forbes.com',
      className: 'forbes',
    },
    {
      href: 'https://www.usatoday.com/story/special/contributor-content/2023/08/22/docus-ai-innova[…]lobal-healthcare-through-ai-powered-diagnostics/70653228007/',
      imageSrc: '/symptom-checker/featured/usa-today.png',
      alt: 'usatoday.com',
      className: 'usatoday',
    },
    {
      href: 'https://www.entrepreneur.com/en-in/news-and-trends/merging-ai-and-international-medical-expertise-to/454513',
      imageSrc: '/symptom-checker/featured/entrepreneur.png',
      alt: 'entrepreneur.com',
      className: 'entrepreneur',
    },
    {
      href: 'https://www.cnbctv18.com/healthcare/docusai-allows-users-to-get-diagnosis-via-ai-chatbot-17436711.htm',
      imageSrc: '/symptom-checker/featured/cnbc.png',
      alt: 'cnbctv18.com',
      className: 'cnbc',
    },
  ];

  return (
    <div className={'featured-by-container'}>
      <div className={'featured-by-wrapper'}>
        {featuredData.map((featured) => (
          <MainLink
            className={`featured-by-link ${featured.className}`}
            key={featured.alt}
            href={featured.href}
            target={'_blank'}
          >
            <Image
              className={'featured-by-img'}
              src={featured.imageSrc}
              alt={featured.alt}
              preview={false}
            />
          </MainLink>
        ))}
      </div>
    </div>
  );
};

export default FeaturedBySection;

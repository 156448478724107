import MainImage from '@/components/global/MainImage';
import MainParagraph from '@/components/global/typography/paragraph/MainParagraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@awesome.me/kit-b89bd90d95/icons/classic/regular';
import DarkHeading3Strong from '@/components/global/typography/heading/Heading3/DarkHeading3Strong';
import HomeServiceCardStyled from '@/components/pages/home/ServicesSection/HomeServiceCard/style';
import GreenIconText from '@/components/global/typography/text/GreenIconText';
import { faCheck } from '@awesome.me/kit-b89bd90d95/icons/classic/solid';
import React from 'react';
import { Col, Row } from 'antd';
import PrimaryGreenLink from '@/components/global/typography/links/PrimaryGreenLink';

interface HomeServiceCardProps {
  title: string;
  description: string;
  imageUrl: string;
  alt: string;
  link: string;
  linkText: string;
  list?: string[];
}
const HomeServiceCard = ({
  title,
  description,
  imageUrl,
  alt,
  link,
  linkText,
  list,
}: HomeServiceCardProps) => {
  //TODO: needs refactor

  return (
    <HomeServiceCardStyled>
      <Row gutter={[16, 16]} align="middle">
        <Col xs={24}>
          <Row gutter={[16, 24]} align={'stretch'}>
            <Col xs={24} md={list ? 24 : 6} className={'self-start'}>
              <MainImage
                src={imageUrl}
                alt={alt}
                loading={'eager'}
                priority={true}
              />
            </Col>
            <Col xs={24} md={list ? 24 : 18}>
              <Row gutter={[16, 16]} align={'stretch'}>
                <Col xs={24}>
                  <DarkHeading3Strong
                    className={list ? 'main-title mb-0' : 'mb-0'}
                  >
                    {title}
                  </DarkHeading3Strong>
                </Col>
                <Col xs={24}>
                  <MainParagraph className={'card-text'}>
                    {description}
                  </MainParagraph>
                </Col>
                {list && (
                  <Col xs={24}>
                    <div className={'simple-steps-wrapper'}>
                      <div className={'simple-steps'}>
                        {list.map((listItem, index) => {
                          return (
                            <GreenIconText
                              key={index}
                              icon={faCheck}
                              text={listItem}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} className={'flex justify-end'}>
          <PrimaryGreenLink href={link}>
            {linkText}
            <FontAwesomeIcon
              className={'icon ml-2'}
              width={20}
              height={14}
              icon={faArrowRightLong}
              color={'#12635C'}
            />
          </PrimaryGreenLink>
        </Col>
      </Row>
    </HomeServiceCardStyled>
  );
};

export default HomeServiceCard;

import styled from 'styled-components';

const IntroSectionStyled = styled.div`
  .intro-general-section {
    display: flex;
    margin-bottom: 40px;
    .text-content-section {
      max-width: 460px;
      margin-right: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .content-title {
        margin-bottom: 24px;
      }

      .content-text {
        margin-bottom: 40px;
      }

      .buttons-wrapper {
        .primary-green-btn {
          margin-bottom: 0;
          margin-right: 28px;
        }
        .primary-green-link {
        }
      }
    }

    .img-content-section {
      width: 600px;
    }
  }

  .please-note-section {
    padding: 0 112px;
    margin-bottom: 24px;
    .note-txt {
      text-align: center;
      padding-bottom: 24px;
    }
    .img-wrapper {
      display: flex;
      justify-content: center;
      .img-item {
        height: 72px;
        &.hippa {
          max-width: 108px;
          width: 100%;
          margin-right: 40px;
        }
        &.gdpr {
          max-width: 75px;
          width: 100%;
        }
      }
    }
    &.form-view {
      padding: 0 160px;
    }
  }
  @media (max-width: 768px) {
    .intro-general-section {
      justify-content: center;
      margin-bottom: 24px;
      .text-content-section {
        margin-right: 0;
        flex-direction: column;
        .buttons-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .primary-green-btn {
            margin-right: 0;
            margin-bottom: 16px;
          }
          .primary-green-link {
            width: fit-content;
          }
        }
      }
      .img-content-section {
        display: none;
      }
    }

    .please-note-section {
      padding: 0;
      &.form-view {
        padding: 0;
      }
    }
  }
`;

export default IntroSectionStyled;

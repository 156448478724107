import {
  faHandHoldingMedical,
  faLanguage,
  faUniversalAccess,
} from '@awesome.me/kit-b89bd90d95/icons/classic/regular';

export const fantasticCardsData = [
  {
    id: 1,
    icon: faLanguage,
    title: 'Advanced natural language understanding',
    text: 'Our AI model has an improved understanding of natural language, enabling it to better comprehend and respond to complex health-related questions and concerns.',
  },
  {
    id: 2,
    icon: faHandHoldingMedical,
    title: 'Personalized assistance',
    text: "The AI model is designed to provide personalized virtual health assistance and recommendations tailored to an individual's medical history, and symptoms offering valuable insights for users.",
  },
  {
    id: 3,
    icon: faUniversalAccess,
    title: 'Accessibility',
    text: 'As an AI-driven solution, it is available round-the-clock, making it a convenient source of health information for users at any time.',
  },
];

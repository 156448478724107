import React, { useEffect, useRef, useState } from 'react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import MainLink from '@/components/global/typography/links/MainLink';
import Script from 'next/script';

const TrustBox = () => {
  const ref = useRef(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <>
      <Script
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
        onLoad={() => setLoading(false)}
      />
      <Row justify={'center'}>
        <Col
          ref={ref}
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="5419b732fbfb950b10de65e5"
          data-businessunit-id="6543b248a377c995fe3bc750"
          data-style-height="20px"
        >
          <MainLink
            href="https://www.trustpilot.com/review/docus.ai"
            target="_blank"
            rel="noopener"
          >
            {!loading && 'Trustpilot'}
          </MainLink>
        </Col>
      </Row>
    </>
  );
};
export default TrustBox;

'use client';

import styled from 'styled-components';
import { Typography } from 'antd';
import React, { ReactNode } from 'react';
import { TitleProps } from 'antd/es/typography/Title';

const { Title } = Typography;

const DarkHeading3StrongStyled = styled(Title)`
  &&& {
    color: #272a3d !important;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
`;

interface DarkHeading3Props extends TitleProps {
  children: ReactNode;
}

const DarkHeading3Strong: React.FC<DarkHeading3Props> = ({
  children,
  ...props
}) => {
  return (
    <div>
      <DarkHeading3StrongStyled level={3} {...props}>
        {children}
      </DarkHeading3StrongStyled>
    </div>
  );
};

export default DarkHeading3Strong;

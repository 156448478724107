'use client';

import React from 'react';
import PrimaryContainer from '@/components/global/containers/PrimaryContainer';
import MainParagraph from '@/components/global/typography/paragraph/MainParagraph';
import DarkHeading2 from '@/components/global/typography/heading/Heading2/DarkHeading2';
import GlobalRecognitionStyled from '@/components/common/sections/GlobalRecognition/style';
import FeaturedBySection from '@/components/common/sections/GlobalRecognition/FeaturedBySection';

const GlobalRecognition = () => {
  return (
    <PrimaryContainer className={''}>
      <GlobalRecognitionStyled>
        <div className={'global-recognition-content'}>
          <DarkHeading2 className={'content-title'}>
            Global Recognition
          </DarkHeading2>
          <MainParagraph className={'content-text'}>
            Docus is recognized by top resources for its innovation in health
            technology. We've been featured by:
          </MainParagraph>
        </div>
        <FeaturedBySection />
      </GlobalRecognitionStyled>
    </PrimaryContainer>
  );
};

export default GlobalRecognition;

import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import SmallParagraph from '@/components/global/typography/paragraph/SmallParagraph';
import MainImage from '@/components/global/MainImage';
import MainLink from '@/components/global/typography/links/MainLink';
import Script from 'next/script';
import TrustBox from '@/components/common/sections/TrustPilotSection/TrustBox';

const TrustPilotSection = () => {
  return <TrustBox />;
};

export default TrustPilotSection;

import React from 'react';
import { IHowToUseCardProps } from '@/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainParagraph from '@/components/global/typography/paragraph/MainParagraph';
import DarkHeading4 from '@/components/global/typography/heading/Heading4/DarkHeading4';

const MediumCardWithIcon = ({ title, text, icon }: IHowToUseCardProps) => {
  return (
    <div className={'htu-card-container'}>
      <div className={'htu-card-icon-wrapper'}>
        <FontAwesomeIcon
          icon={icon}
          color={'#1A847C'}
          style={{ height: '32px', width: 'auto' }}
        />
      </div>
      <div className={'htu-card-title-wrapper'}>
        <DarkHeading4 className={'content-title'}>{title}</DarkHeading4>
      </div>
      <div className={'htu-card-text-wrapper'}>
        <MainParagraph className={'content-text mb-0'}>{text}</MainParagraph>
      </div>
    </div>
  );
};

export default MediumCardWithIcon;

import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import MainParagraph from '@/components/global/typography/paragraph/MainParagraph';

interface Props {
  icon: IconDefinition;
  text: ReactNode;
  iconSize?: SizeProp;
  className?: string;
}

const StyledContainer = styled.div`
  &&& {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-right: 16px;
    margin-bottom: 8px;

    & span {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #272a3d;
    }
  }
`;

const GreenIconText: React.FC<Props> = ({
  icon,
  text,
  iconSize,
  className,
}: Props) => {
  return (
    <StyledContainer className={className}>
      <FontAwesomeIcon icon={icon} size={iconSize} color={'#1A847C'} />
      <MainParagraph className={'mb-0'}>{text}</MainParagraph>
    </StyledContainer>
  );
};

export default GreenIconText;
